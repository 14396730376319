
import { defineComponent } from 'vue';
import ItemHeader from '@/components/footerlink/ItemHeader.vue';
import ItemWrapper from '@/components/footerlink/ItemWrapper.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'CookiePolicy',
  components: {
    ItemHeader,
    ItemWrapper,
  },
  setup() {
    const { t } = useI18n();
    const table = {
      th: [
        { th: t('cookie.table.th.th1') },
        { th: t('cookie.table.th.th2') },
        { th: t('cookie.table.th.th3') },
      ],
      td: [
        [
          t('cookie.table.td.td1'),
          t('cookie.table.td.td2'),
          t('cookie.table.td.td3'),
        ],
        [
          t('cookie.table.td.td4'),
          t('cookie.table.td.td5'),
          t('cookie.table.td.td6'),
        ],
        [
          t('cookie.table.td.td7'),
          t('cookie.table.td.td8'),
          t('cookie.table.td.td9'),
        ],
        [
          t('cookie.table.td.td10'),
          t('cookie.table.td.td11'),
          t('cookie.table.td.td12'),
        ],
        [
          t('cookie.table.td.td13'),
          t('cookie.table.td.td14'),
          t('cookie.table.td.td15'),
        ],
        [
          t('cookie.table.td.td16'),
          t('cookie.table.td.td17'),
          t('cookie.table.td.td18'),
        ],
        [
          t('cookie.table.td.td19'),
          t('cookie.table.td.td20'),
          t('cookie.table.td.td21'),
        ],
        [
          t('cookie.table.td.td22'),
          t('cookie.table.td.td23'),
          t('cookie.table.td.td24'),
        ],
        [
          t('cookie.table.td.td25'),
          t('cookie.table.td.td26'),
          t('cookie.table.td.td27'),
        ],
      ],
    };
    const browser = [
      'Firefox',
      'Chrome',
      'Internet',
      'Explorer',
      'Safari',
      'Opera',
    ];

    return {
      t,
      table,
      browser,
    };
  },
});
